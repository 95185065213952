<template lang="pug">
  .switch-component(@click="$emit('click')")
    span {{ isHidden ? $t("plan_settings.basic_settings.open") : $t("plan_settings.basic_settings.close") }}
    FaIcon(:icon="isHidden ? 'chevron-down' : 'chevron-up'")
</template>

<script>
  export default {
    props: {
      isHidden: {
        type: Boolean,
        default: true
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .switch-component
    cursor: pointer
    -webkit-touch-callout: none
    -webkit-user-select: none
    -khtml-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    color: $default-purple

    span
      font-size: 0.8rem
      margin-right: 10px
</style>
